* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

:root {
  --primary: #FE9900;
  --primary-dark: #d78304;
  --secondary: #4043A5;
  --secondary-dark: #31348d;
  --foreground: #000000;
  --background: #FFFFFF;
  --text: #000000;
  --border: #E1E1E1;
}

.App {
  min-height: 100vh;
  width: 100%;
  max-width: 430px;
  margin: auto;
  background-image: url(./assets/img/bg-pattern.svg);
  background-position: top;
  background-repeat: repeat;
  background-size: 100%;
  box-shadow: 0px 0px 8px 4px #00000010
}

.app-content {
  background-image: url(./assets/img/bg-footer.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}

.content-wrapper {
  height: auto;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 27px 20px 32px;
  height: auto;
  width: 100%;

  &.header-small {
    justify-content: space-between;
  }
}

.content-box {
  width: 100%;
  max-width: 368px;
  height: auto;
  background: rgba(255, 255, 255, 0.68);
  border-radius: 27px;
  margin: auto;
  padding: 16px;
  min-height: calc(100vh - 242px);
}

.goallogo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px 0px 23px;
  width: 100%;
  max-width: 237px;
  margin: auto;
}

.leaderboardlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 23px;
  width: 100%;
  max-width: 260px;
  margin: auto;
}

.leaderboard-list {
  list-style-type: number;
  list-style-position: inside;
  padding-left: 0px;
  display: grid;
  grid-gap: 16px;
}

.leaderboard-list li {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
  background-color: var(--primary);
  border-radius: 10px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leaderboard-list li p {
  margin: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 240px;
}

.leaderboard-list li .score {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  min-width: 70px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-bottom: 13px;
}

.btn {
  padding: 15px 12px;
  border-radius: 10px;
  font-weight: 800;
  font-size: 24px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
  }
}

.btn-primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.btn-secondary {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
}

.btn-secondary:hover {
  background-color: var(--secondary-dark);
  border: 1px solid var(--secondary-dark);
}

.btn-full {
  width: 100%;
}

.btn-rounded {
  border-radius: 50px;
  aspect-ratio: 1;
  height: 102px;
  font-size: 28px;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary);
}

.text-secondary {
  color: var(--secondary);
}

.text-danger {
  color: red;
}

h2 {
  font-size: 24px;
  font-weight: 700 !important;
}

.form-control {
  border: 1px solid var(--secondary);
  border-radius: 10px;
  padding: 8px 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  color: var(--secondary);
  width: 100%;
  max-width: 368px;
  margin: 10px 0px;
  height: 50px;
  text-align: center;
  display: block;
}

.terms {
  display: flex;
  gap: 5px;
  text-align: left;
  margin-bottom: 10px;
}

small {
  font-size: 10px;
}

label {
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 15px;
  margin-bottom: 10px;
  font-weight: 400;
}

input[type="checkbox"] {
  position: relative;
  height: 20px;
  width: 20px;

  &::before {
    content: "";
    height: 20px;
    width: 20px;
    border-radius: 2px;
    border: 1px solid var(--border);
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--background);
    z-index: 1;
    cursor: pointer;
  }

  &::after {
    content: "";
    height: 0px;
    width: 0px;
    border-radius: 1px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    cursor: pointer;
  }

  &:checked {
    &::before {
      border-color: var(--primary);
      background-color: var(--primary);
    }

    &::after {
      height: 8px;
      width: 4px;
      left: 8px;
      top: 25%;
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
    }
  }
}

.questions-options {
  list-style-type: none;
  padding: 0;

  .form-check {
    background-color: var(--background);
    font-size: 20px;
    font-weight: 600;
    padding: 15px;
    text-align: center;
    display: block;
    border: 2px solid var(--border);
    border-radius: 10px;
    transition: all 0.3s ease;

    input {
      display: none;
    }

    &.checked {
      background-color: var(--primary);
      color: #fff;
      border-color: var(--primary);
    }
  }
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: var(--secondary);
  border-radius: 40px;
  padding: 14px 25px;
  max-width: 213px;
  margin: auto;
  margin-bottom: 35px;
  grid-gap: 10px;

  h2 {
    font-size: 36px;
    font-weight: 700 !important;
    color: #fff;
    text-align: center;
    margin: 0;
    min-width: 109px;
    letter-spacing: 2px;
  }
}

